*,
::after,
::before {
    box-sizing: border-box;
}

html {
    font-size: 100%;
} /*16px*/

:root {
    /* colors */
    --primary-50: hsl(207, 64%, 84%);
    --primary-100: hsl(207, 64%, 77%);
    --primary-200: hsl(207, 64%, 68%);
    --primary-300: hsl(207, 64%, 61%);
    --primary-400: hsl(207, 64%, 55%);
    --primary-500: hsl(207, 64%, 50%);
    --primary-600: hsl(207, 64%, 45%);
    --primary-700: hsl(207, 64%, 39%);
    --primary-800: hsl(207, 64%, 32%);
    --primary-900: hsl(207, 64%, 23%);

    --primary-black: #363c43;
    --primary-black-light: #434950;

    /* grey */
    --grey-50: #f0f4f8;
    --grey-100: #d9e2ec;
    --grey-200: #bcccdc;
    --grey-300: #9fb3c8;
    --grey-400: #829ab1;
    --grey-500: #627d98;
    --grey-600: #486581;
    --grey-700: #334e68;
    --grey-800: #243b53;
    --grey-900: #102a43;
    /* rest of the colors */
    --black: #222;
    --white: #fff;
    --red-light: #f8d7da;
    --red: #e74c3c;
    --red-dark: #842029;
    --green-light: #d1e7dd;
    --green: #07bc0c;
    --green-dark: #0f5132;

    /* fonts  */
    --headingFont: 'Roboto', Sans-Serif;
    --bodyFont: 'Cabin', Sans-Serif;
    --small-text: 0.875rem;
    --extra-small-text: 0.7rem;
    /* rest of the vars */
    --backgroundColor: var(--white);
    --textColor: var(--grey-900);
    --headingColor: var(--grey-900);
    --borderRadius: 0.25rem;
    --letterSpacing: 1px;
    --transition: 0.3s ease-in-out;
    --max-width: 1120px;
    --fixed-width: 760px;
    --fluid-width: 90vw;
    /* box shadow*/
    --shadow-1: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    --shadow-2: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    --shadow-3: 0 0 20px 0 rgb(0, 0, 0, 0.15);
}

body {
    background: var(--backgroundColor);
    font-family: var(--bodyFont);
    font-weight: 400;
    line-height: 1.4;
    color: var(--textColor);
}

body.sidebar-open {
    overflow-y: hidden !important;
}

p {
    margin-bottom: 1.5rem;
}

h1,
h2,
h3,
h4,
h5 {
    color: var(--headingColor);
    margin: 0;
    margin-bottom: 1.3rem;
    font-family: var(--headingFont);
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: var(--letterSpacing);
}

h1 {
    font-size: 2.4rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.7rem;
}

h4 {
    font-size: 1.45rem;
}

h5 {
    font-size: 1.25rem;
}

small {
    font-size: var(--small-text);
}

a {
    font-weight: 600;
    text-decoration: underline;
    color: var(--black);
}
a,
button {
    color: inherit;
    cursor: pointer;
    background-color: transparent;
    border: none;
    padding: 0;
}
button:disabled {
    cursor: not-allowed;
}
ul,
ol {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

hr {
    display: block;
    width: 100%;
    border: none;
    background-color: var(--grey-100);
    height: 1px;
    border-radius: 1.55rem;
    margin: 0;
}

body.modal-open {
    overflow: hidden;
}

.img {
    width: 100%;
    display: block;
    object-fit: cover;
}

.logo {
    display: block;
    width: 100%;
    max-width: 180px;
}

.hero-text {
    font-size: 1.15rem !important;
}

.text-center {
    text-align: center;
}

.banner {
    margin: 2rem 0;
    padding: 4rem 0;
    background-color: var(--grey-50);
}

/* buttons */

.btn {
    text-align: center;
    line-height: 1;
    text-decoration: none;
    font-weight: 600;
    cursor: pointer;
    color: var(--white);
    background: var(--primary-500);
    border: solid 2px var(--primary-500);
    border-radius: 1.55rem;
    padding: 0.55rem 1.25rem;
    transition: var(--transition);
    text-transform: capitalize;
    display: inline-block;
    letter-spacing: var(--letterSpacing);
}
.btn:hover {
    border-color: var(--primary-400);
    background: var(--primary-400);
    box-shadow: var(--shadow-2);
}
.btn:disabled {
    color: var(--white);
    background: var(--grey-200);
    border-color: var(--grey-200);
}
.btn-hipster {
    color: var(--primary-500);
    background: var(--primary-200);
}
.btn-hipster:hover {
    color: var(--primary-200);
    background: var(--primary-700);
}
.btn-outline {
    background: transparent;
    color: var(--primary-500);
    border-color: var(--primary-500);
}
.btn-outline:hover {
    background: transparent;
    box-shadow: var(--shadow-2);
    color: var(--primary-300);
    border-color: var(--primary-300);
}
.btn-block {
    width: 100%;
}
.btn-hero {
    font-size: 1.1rem;
    padding: 0.75rem 1.45rem;
}
.btn-danger {
    background: var(--red-light);
    color: var(--red-dark);
}
.btn-danger:hover {
    background: var(--red-dark);
    color: var(--white);
}
.btn-danger:disabled {
    background: var(--red-dark);
    color: var(--white);
}
/* alerts */
.alert {
    padding: 0.375rem 0.75rem;
    margin-bottom: 1rem;
    border-color: transparent;
    border-radius: var(--borderRadius);
    text-align: center;
    letter-spacing: var(--letterSpacing);
}

.alert-danger {
    color: var(--red-dark);
    background: var(--red-light);
}
.alert-success {
    color: var(--green-dark);
    background: var(--green-light);
}
/* form */

.form {
    width: 100%;
    max-width: var(--fixed-width);
    padding: 2rem 0;
    margin: 2rem auto;
}
.form-label {
    display: block;
    margin-bottom: 0.25rem;
    margin-left: 1rem;
}
.form-label.disabled {
    opacity: 0.7;
}
.form-input,
.form-textarea,
.form-select {
    width: 100%;
    padding: 0.65rem 1.15rem;
    border-radius: 1.55rem;
    background: var(--backgroundColor);
    border: 1px solid var(--grey-200);
    color: var(--grey-600);
}
.form-textarea {
    display: block;
    resize: none;
    height: 100px;
}
.form-textarea + .indicator {
    text-align: right;
}
.form-error {
    display: block;
    color: var(--red);
    font-size: var(--small-text);
    margin-top: 0.25rem;
    margin-left: 1rem;
}

::placeholder {
    font-family: inherit;
    color: var(--grey-300);
}
.form-input:read-only {
    background-color: var(--grey-50);
}
.form-input:read-only:focus {
    outline: none;
}
/* alert */

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}

.loading {
    width: 6rem;
    height: 6rem;
    border: 5px solid var(--primary-800);
    border-radius: 50%;
    border-top-color: var(--primary-400);
    animation: spinner 2s linear infinite;
}
.loading-center {
    margin: 0 auto;
}

/* title */

.title {
    text-align: center;
}

.title-underline {
    background: var(--primary-500);
    width: 7rem;
    height: 0.25rem;
    margin: 0 auto;
    margin-top: -1rem;
}

.container {
    width: var(--fluid-width);
    max-width: var(--max-width);
    margin: 0 auto;
}
.full-page {
    min-height: 100vh;
}

.markdown-container {
    max-width: var(--max-width);
    margin: 0 auto;
    color: var(--grey-500);
}
.markdown-container .post-title {
    font-size: 1.8rem;
    text-align: center;
    margin: 0 auto;
    max-width: 700px;
}
.markdown-container .post-image {
    width: auto;
    max-width: 100%;
    max-height: 600px;
    margin: 3rem auto;
    margin-bottom: 4.5rem;
}
.markdown-container .post-content {
    max-width: 800px;
    margin: 0 auto;
    font-size: 1.125rem;
}
.markdown-container .post-content > p {
    font-size: 1.25rem;
}
.markdown-container .post-content *:not(iframe) {
    width: 100%;
}
.markdown-container .post-content ul,
.markdown-container .post-content ol {
    padding-left: 2.5rem;
    margin-bottom: 1.8rem;
}
.markdown-container .post-content ul p,
.markdown-container .post-content ol p {
    margin: 0;
}
.markdown-container .post-content ol {
    list-style-type: decimal;
}
.markdown-container .post-content ul {
    list-style-type: disc;
}
.markdown-container .post-content ul ul {
    list-style-type: circle;
}
.markdown-container .post-content ul ul ul {
    list-style-type: square;
}
.markdown-container .post-content ul ol,
.markdown-container .post-content ul ul,
.markdown-container .post-content ol ol,
.markdown-container .post-content ol ul {
    margin-bottom: 0;
}
.markdown-container .post-content img,
.markdown-container .post-content video {
    display: block;
    max-width: 600px;
    max-height: 400px;
    margin: 0 auto;
}
.markdown-container .post-content img {
    object-fit: contain;
}
.markdown-container .post-content iframe {
    display: block;
    margin: 0 auto;
    margin-bottom: 2rem;
    max-width: 100%;
}
.markdown-container .post-content h1 {
    font-size: 1.9rem;
    line-height: 1.2;
}
.markdown-container .post-content h2 {
    font-size: 1.65rem;
    line-height: 1.2;
}
.markdown-container .post-content h3 {
    font-size: 1.45rem;
    line-height: 1.2;
}
.markdown-container .post-content p {
    margin-bottom: 2rem;
    line-height: 1.5;
}
.markdown-container .post-content a {
    color: var(--primary-400);
    text-decoration: none;
}

form.search-container {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 2rem;
    row-gap: 1rem;
}
form.search-container .btn[type='submit'] {
    min-height: 44px;
    margin-top: 1rem;
}

.Toastify__toast {
    --toastify-color-info: var(--primary-400);
    --toastify-color-error: var(--red);
    --toastify-color-success: var(--green);

    font-family: var(--bodyFont);
}

.Toastify__toast-body > div:last-child {
    text-align: left;
}

.iubenda-tp-btn-hidden {
    display: none !important;
    opacity: 0 !important;
    pointer-events: none !important;
}

@media screen and (min-width: 543px) {
    form.search-container {
        grid-template-columns: 1fr 1fr;
        align-items: flex-end;
    }
}

@media screen and (min-width: 768px) {
    h1 {
        font-size: 2.95rem;
    }

    h2 {
        font-size: 2.6rem;
    }

    h3 {
        font-size: 2.2rem;
    }

    h4 {
        font-size: 1.75rem;
    }

    h5 {
        font-size: 1.45rem;
    }

    .hero-text {
        font-size: 1.25rem !important;
    }
}

@media screen and (min-width: 992px) {
    body.sidebar-open {
        overflow-y: auto !important;
    }
}

@media (min-width: 1120px) {
    form.search-container {
        grid-template-columns: 1fr 1fr 1fr;
    }
}
